import {
	ACTION_UPDATE_ACTIVITY_STATE,
	MUTATION_SET_ACTIVITY_STATE,
	MUTATION_SET_COMPRESSED_ACTIVITY_STATE,
	STATE_ACTIVITY_STATE,
	STATE_COMPRESSED_ACTIVITY_STATE,
	STORE_MODULE_ACTIVITY_STATE
} from '@/store_constants/content';
import { gzipSync } from 'browserify-zlib';
import mapKey from '../../../components/lessonPlan/controllers/LessonPlanController/mapKey';

export default {
	name: STORE_MODULE_ACTIVITY_STATE,
	namespaced: true,
	state: {
		[STATE_ACTIVITY_STATE]: null,
		[STATE_COMPRESSED_ACTIVITY_STATE]: null
	},
	getters: {},
	mutations: {
		[MUTATION_SET_ACTIVITY_STATE](state, activityState) {
			state[STATE_ACTIVITY_STATE] = activityState;
		},
		[MUTATION_SET_COMPRESSED_ACTIVITY_STATE](state, compressedActivityState) {
			state[STATE_COMPRESSED_ACTIVITY_STATE] = compressedActivityState;
		}
	},
	actions: {
		[ACTION_UPDATE_ACTIVITY_STATE]({ commit }, activityState) {
			commit(MUTATION_SET_ACTIVITY_STATE, activityState);

			const compressedActivityState = compress(activityState);
			commit(MUTATION_SET_COMPRESSED_ACTIVITY_STATE, compressedActivityState);
		}
	}
};

function compress(activity_state) {
	if (!activity_state) return null;

	const activitySCORM = JSON.parse(JSON.stringify(activity_state));
	const sim = JSON.stringify(activitySCORM, simplify);
	const bufferCompressed = gzipSync(sim);
	const scorm_suspend_data = bufferCompressed.toString('base64');
	return scorm_suspend_data;
}

function simplify(k, v) {
	if (v && typeof v === 'object') {
		Object.entries(mapKey).forEach(entry => {
			if (entry[0] in v) {
				updateKey(v, entry[0], entry[1]);
			}
		});
	}
	if (k === 'c' || k === 'C' || k === 'S') {
		return v === true ? 1 : v === false ? 0 : v;
	}
	return v;
}

function updateKey(object, oldKey, newKey) {
	delete Object.assign(object, { [newKey]: object[oldKey] })[oldKey];
}